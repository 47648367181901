import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import '@fontsource/inter';

// Pages
import HomePage from './pages/HomePage';
import Layout from './components/Layout';

function App() {
  return (
    <React.Fragment>
      <CssBaseline />
      <Layout>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<HomePage />} />
          </Routes>
        </BrowserRouter>
      </Layout>
    </React.Fragment>
  );
}

export default App;
