import React from 'react';
import TopBar from '../TopBar';
import Footer from '../Footer';

interface Props {
  children: React.ReactNode;
}

function Layout(props: Props) {
  return (
    <React.Fragment>
      <TopBar>{props.children}</TopBar>
      <Footer />
    </React.Fragment>
  );
}

export default Layout;
