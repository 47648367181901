import React from 'react';
import fotoDePharmamedic from '../../assets/imgs/store.jpg';
import { Box, Button, Stack, Typography } from '@mui/material';
import { Container } from '@mui/system';

import PlaceIcon from '@mui/icons-material/Place';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CallIcon from '@mui/icons-material/Call';

function HomePage() {
  return (
    <>
      <img src={fotoDePharmamedic} alt="Foto de Pharmamedic" width="100%" />
      <Container>
        <Stack spacing={5} pt={5}>
          <Box>
            <Typography variant="h3">¿Dónde Estamos Ubicados?</Typography>
            <Typography variant="body1">
              <PlaceIcon /> Barrio El Centro entre 7 y 8 calle Avenida
              Nicaragua.
            </Typography>
          </Box>

          <Box>
            <Typography variant="h3">Acerca de Nosotros</Typography>
            <Typography variant="body1">
              Pharmamedic abrio sus puertas en Tela el 2022 ofreciendo
              diferentes servicios cubriendo las necesidad de los Hondureños.
            </Typography>
          </Box>

          <Box>
            <Typography variant="h3">Contactenos</Typography>
            <Typography variant="body1">
              Puedes escribirnos via whatsapp o llamarnos directamente al:
            </Typography>
            <Button
              variant="text"
              component="a"
              href="https://wa.me/+50433074888"
              target="_blank">
              <WhatsAppIcon /> +50433074888{' '}
            </Button>
            <Button variant="text" component="a" href="tel:+50433074888">
              <CallIcon /> +50433074888{' '}
            </Button>
          </Box>
        </Stack>
      </Container>
    </>
  );
}

export default HomePage;
