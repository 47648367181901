import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

function Footer() {
  return (
    <Container>
      <Box component="footer" width="100%" textAlign="center" p={5}>
        <Typography variant="overline">
          Pharmamedic, Tu Farmacia en Tela
        </Typography>
      </Box>
    </Container>
  );
}

export default Footer;
